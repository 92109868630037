export default class AppConstants {
  static get CLIENT_VERSION() {
    return "1.0.0";
  }
  static get ADSENSE_CLIENT_ID() {
    return import.meta.env.PUBLIC_ENV__ADSENSE_CLIENT_ID;
  }
  static get TURNSTILE_SITE_KEY() {
    return import.meta.env.PUBLIC_ENV__TURNSTILE_SITE_KEY;
  }
  static get CANONICAL_URL() {
    return import.meta.env.PUBLIC_ENV__CANONICAL_URL;
  }
  static get ADMIN_PANEL_URL() {
    return import.meta.env.PUBLIC_ENV__ADMIN_PANEL_URL;
  }
  static get GENERIC_API_ROUTE() {
    return import.meta.env.PUBLIC_ENV__API_URL;
  }
  static get API_ROUTE() {
    return AppConstants.GENERIC_API_ROUTE + "/v1/portal";
  }
  static get ATTACHMENT_STORAGE() {
    return import.meta.env.PUBLIC_ENV__ATTACHMENT_STORAGE_URL;
  }
  static get GOOGLE_OAUTH_LINK() {
    return import.meta.env.PUBLIC_ENV__GOOGLE_OAUTH_URL;
  }
  static get YOUTUBE_OAUTH_LINK() {
    return import.meta.env.PUBLIC_ENV__YOUTUBE_OAUTH_URL;
  }
  static get DISCORD_OAUTH_LINK() {
    return import.meta.env.PUBLIC_ENV__DISCORD_OAUTH_URL;
  }
  static get FACEBOOK_OAUTH_LINK() {
    return import.meta.env.PUBLIC_ENV__FACEBOOK_OAUTH_URL;
  }
  static get STEAM_OPENID_AUTH_LINK() {
    return import.meta.env.PUBLIC_ENV__STEAM_OPENID_AUTH_URL;
  }
  static get STEAM_OPENID_INTEGRATE_LINK() {
    return import.meta.env.PUBLIC_ENV__STEAM_OPENID_INTEGRATE_URL;
  }
  static get COOKIES_ACCEPTED_COOKIE() {
    return "JAROCK_COOKIES_ALLOWED";
  }
  static get LIGHT_MODE_COOKIE() {
    return "JAROCK_LIGHT_MODE";
  }
  static get ACCESS_TOKEN_COOKIE() {
    return "JAROCK_AT";
  }
  static get REMEMBER_ME_COOKIE() {
    return "JAROCK_REMEMBER_ME";
  }
  static get REFRESH_TOKEN_COOKIE() {
    return "JAROCK_RT";
  }
  static get USER_ID_COOKIE() {
    return "JAROCK_UID";
  }
  static get CLIENT_ID_COOKIE() {
    return "JAROCK_CLIENT_ID";
  }
  static get REFRESH_SPAM_COUNTER() {
    return "REFRESH_SPAM_COUNT";
  }
}
